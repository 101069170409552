import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/admin/admin.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {
  
  detailText:String;

  constructor(
    public dialogRef: MatDialogRef<TermsAndConditionsComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    // this.detailText = this.data.detailText;
    this.getPrivayPolicyText();
  }

  getPrivayPolicyText(){
    this.authService.getPrivayPolicy().subscribe( resp => {
      if(resp?.value?.privacyPolicy){
        this.detailText = resp?.value?.privacyPolicy;
      }
    }, error => {
      this.detailText = undefined;
    });
  }

  submitForm(){
    this.dialogRef.close('CONFIRM');
  }

  close(): void {
    this.dialogRef.close('CLOSE');
  }

}
