<div class="dialog-form">
    <div class="ff_dialog_box">
        <div class="ff_header">
            <div class="ff_flex ff_align_center">
                <div class="ff_title">
                    Privacy Policy
                </div>
            </div>
            <img src="/assets/images/cross-button.svg" alt="Close" (click)="close()" class="ff_close_icon">
        </div>
    </div>
    <div class="table-content">
        <ng-container *ngIf="!detailText; else detailedText">
            <p class="policy-error">
                Couldn't fetch Privacy Policy. Please contact support.
            </p>
        </ng-container>
        <ng-template #detailedText>
            <div [innerHTML]="detailText"></div>
            <div class="action-btn">
                <button class="ff_button_32_transparent" (click)="close()">Cancel</button>
                <button class="ff_button_32-80 ff_leftM_12" (click)="submitForm()">Accept Privacy Policy</button>
            </div>
        </ng-template>
    </div>
</div>